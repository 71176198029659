import React from 'react';

import imageShopWithSpriveCashbackVisual from '../../assets/images/illustrations/shop-with-sprive-cashback-visual.png';

import { SecondaryTitle } from '../../components/Typography';
import BrandsList from '../../components/BrandsList';
import CustomImage from '../../components/Image/Image';

const ShopWithSpriveSection = ({isMobile}) => (
    <section className="shop-with-sprive-section">
      <div className="container shop-with-sprive-section__second-part">
        <div className="row">
          <div className={isMobile ? "col-lg-11 mx-auto" : ""}>
            <div className="row row-margin">
              <div className="text col-md-6">
                <SecondaryTitle>
                  Shop and earn {!isMobile ? <br /> : ''} extra cash {isMobile ? <br /> : ''} towards your mortgage
                </SecondaryTitle>
                {isMobile ? <BrandsList /> : null}
                <p className="paragraph">
                When you do your everyday shopping through the app, you’ll earn cashback within 15 minutes to put towards your mortgage in one tap. 
                </p>
                <p className="paragraph">There are over 50 brands available for you in the app so you can earn a little extra each time you shop.</p>
                {!isMobile ? <BrandsList /> : null}
              </div>
              {!isMobile && (
                <div className="image col-md-5 offset-md-1">
                  <CustomImage
                    className="w-100"
                    src={imageShopWithSpriveCashbackVisual}
                    alt="Shop with Sprive Brands"
                    loading="lazy"
                  />
                </div>
              )}
            </div>
            <span className='separator' />
          </div>
        </div>
      </div>
    </section>
  );

export default ShopWithSpriveSection